.MainContainerBusinessPageBuild {
  position: absolute; /* Layer each container */
  top: 0;
  left: 0;
  width: 100%; /* Make them take up the full width */
  height: 100%; /* Make them take up the full height */
  display: flex; /* Optional: If you want to use flexbox within each screen */
  /* justify-content: center; Center content horizontally */
  /* align-items: center; Center content vertically */

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.BusinessPageVisualizerCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.BusinessPageVisualizerCon2 {
  display: flex;
  padding: 2px 10px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  background: var(--app-orange, #ff7043);
}
.BusinessPageVisualizerNameText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.BusinessPageVisualizerSectionText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.BusinessPageVisualizerCon3 {
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: scroll;
  background: var(--app-white, #fff);
}
/* .BusinessPageEditorCon1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: 400px;
  overflow: hidden;

  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
}
.BusinessPageEditorCon1Hidden {
  height: 0px;
} */

.BusinessPageEditorCon1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  height: 65%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
  transition: height 0.3s ease, padding 0.3s ease;
}

.BusinessPageEditorCon1Hidden {
  height: 0px;
  padding: 0; /* Ensures padding doesn't interfere with the hidden state */
  overflow: hidden;
}

.BusinessPageEditorCon2 {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
  border-top: 1px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 1px rgba(0, 0, 0, 0.25) inset;
  z-index: 200;
}
.BusinessPageEditorCon2Name {
  flex: 1 0 0;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.BusinessPageEditorConSilderUopDownCon1 {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.BusinessPageEditorConSilderUopDownCon2 {
  display: flex;
  width: 48px;
  height: 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #fff;
}
.BusinessPageEditorConSectionName {
  flex: 1 0 0;
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.BusinessPageEditorCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 12px;
  padding-bottom: 32px;
  flex: 1 0 0;
  align-self: stretch;

  background: none;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
}
.BusinessPageEditorCon4 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  box-sizing: border-box;
  border-radius: 15px;
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.BusinessPageEditorCon5 {
  display: flex;
  padding: 0px 16px 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.BusinessPageEditorCon6 {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.BusinessPageEditorButtonCreatePage {
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-gold-yellow, #f4c542);

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}
.BusinessPageEditorButtonBack {
  display: flex;
  padding: 6px 12px 6px 6px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 12px 0px 0px 12px;
  background: var(--app-orange, #ff7043);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BusinessPageEditorButtonCancel {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--app-charcoal-dark, #2b2b2b);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BusinessPageEditorButtonNext {
  display: flex;
  padding: 6px 6px 6px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 0px 12px 12px 0px;
  background: var(--app-orange, #ff7043);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessPageEditorButtonInactive {
  background: #ff6f4384;

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Start Visualizer */
.BusinessEditorBusinessHeaderCon1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  /* z-index: 1; */
}
.BusinessEditorBusinessHeaderCon2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(179deg, rgba(0, 0, 0, 0) -11.63%, rgba(0, 0, 0, 0.8) 99.49%);
}
.BusinessEditorBusinessHeaderCon3 {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  z-index: 1;
}
.BusinessEditorBusinessHeaderCon4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.BusinessEditorBusinessHeaderCon5 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessEditorBusinessHeaderCon10 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
}
.BusinessEditorBusinessHeaderImportantIconExit {
  height: 32px;
}
.BusinessEditorBusinessHeaderImportantIconVerified {
  height: 20px;
}
.BusinessEditorBusinessHeaderVerifiedBadgeCon1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  /* display: flex;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 50%;
  border-radius: 12px 12px 6px 6px; 
  background: var(
    --Mercado-MAI-pro,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset; */
}
.BusinessEditorBusinessHeaderVerifiedBadgeText {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.BusinessEditorBusinessHeaderBusinessName {
  align-self: stretch;
  color: var(--app-white, #fff);
  text-align: center;

  /* text shadow 1 */
  /* text-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.9); */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}
.BusinessEditorBusinessHeaderBusinessShortDescription {
  align-self: stretch;
  color: var(--app-white, #fff);
  text-align: center;

  /* text shadow 1 */
  text-shadow: 1px 1.5px 4px rgba(0, 0, 0, 0.9);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
/* .BusinessEditorBusinessHeaderCon6 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #888;
  overflow: hidden;
} */

.BusinessEditorBusinessHeaderCon6 {
  width: 120px;
  height: 120px;
  border-radius: 50%; /* Better to use 50% for perfect circles */
  border: 2px solid #888;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--app-gray-medium);
}

.BusinessEditorBusinessHeaderCon6Img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}
.BusinessEditorBusinessHeaderCon6ImgBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  mix-blend-mode: multiply;
  z-index: 0;
}
.BusinessEditorBusinessHeaderCon7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.BusinessEditorBusinessHeaderCon8B {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.BusinessEditorBusinessHeaderCon8 {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessEditorBusinessHeaderButtonCon1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .BusinessEditorBusinessHeaderButtonCon2 {
  display: flex;
  width: 38px;
  height: 38px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.7);

  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
} */

.BusinessEditorBusinessHeaderButtonCon2 {
  display: flex;
  width: 38px;
  height: 38px;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  border-radius: 50px; /* Makes the button round */
  background: rgba(0, 0, 0, 0.7);

  /* Round button white shadow */
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.BusinessEditorBusinessHeaderButtonLabel {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.BusinessEditorBusinessHeaderCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
/* End Visualizer */
/* Start Listings */
.ListingsExampleDisplayCon1 {
  /* display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  overflow: hidden; */
  display: flex;
  width: 100%;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  background: var(--app-beige);
}
.ListingsExampleDisplayCon2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0px 12px;
}
.ListingsExampleDisplayHeaderTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ListingsExampleDisplayHeaderSeeAll {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.ListingsExampleDisplayCon3 {
  display: flex;
  padding: 0px 18px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  overflow-x: scroll;
}
/* End Listings */
/* Start Listing */
.ListingExampleVeriticalCon1 {
  display: flex;
  width: 143px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
}
.ListingExampleVeriticalCon2 {
  display: flex;
  height: 54px;
  padding: 25px 58px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  background: rgba(88, 88, 88, 0.8);
}
.ListingExampleVeriticalCon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  border: 0.5px solid #999;
  background: #c3c3c3;
}
.ListingExampleVeriticalCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingExampleVeriticalCon5 {
  display: flex;
  padding: 2px 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 0px 0px 2px 2px;
  background: #999;
}
.ListingExampleVeriticalTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ListingExampleVeriticalCon6 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.ListingExampleVeriticalCon7 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.ListingExampleVeriticalCon8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}
.ListingExampleVeriticalCon9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ListingExampleVeriticalCon10 {
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 0px 3px;
  background: var(--Item-backg-highlight, #ff3c00);
}
.ListingExampleVeriticalOnDiscount {
  color: var(--Item-text-highlight, #ffa500);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingExampleVeriticalPrice {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ListingExampleVeriticalCondition {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ListingExampleVeriticalCon11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.ListingVeriticalPriceBeforeText {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingVeriticalPriceBeforePrice {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ListingExampleVeriticalDescription {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
}
.ListingExampleVeriticalCon12 {
  display: flex;
  height: 30px;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.ListingExampleVeriticalCon13 {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ListingExampleVeriticalCon14 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 27px;
  background: var(--app-charcoal-dark, #2b2b2b);
  box-shadow: 0px 2px 4px 1px #fff;
}
.ListingExampleVeriticalCon15 {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 27px;
  background: var(--app-charcoal-dark, #2b2b2b);
  box-shadow: 0px 2px 4px 1px #fff;
}
.ListingExampleVeriticalCon16 {
  display: flex;
  align-items: center;
  gap: 2px;
}
.ListingExampleVeriticalViewsText {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* End Listing */
/* Start Bsiness Info */

.BusinessInfoPortionLinkCon1 {
  display: flex;
  padding: 0px 6px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.BusinessInfoPortionLinkCon1B {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
}
.BusinessInfoPortionLinkText {
  width: 100%;
  flex: 1 0 0;
  color: var(--app-link-color, #00349b);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.BusinessInfoCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
}
.BusinessInfoCon2 {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--app-gray-medium, #6d6d6d);
}
.BusinessInfoHeaderText {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.BusinessInfoCon3 {
  display: flex;
  /* height: 157px; */
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}

.BusinessInfoPortionCon1 {
  display: flex;
  padding: 12px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  background: var(--app-white, #fff);
}
.BusinessInfoPortionCon2 {
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--app-gray-soft, #e0e0e0);
}
.BusinessInfoPortionHeaderText {
  flex: 1 0 0;

  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
}

.BusinessInfoLocationCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.BusinessInfoLocationCon2 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.BusinessInfoLocationHeaderText {
  align-self: stretch;

  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
}
.BusinessInfoLocationCon3 {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.BusinessInfoLocationDescriptionText {
  flex: 1 0 0;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.BusinessInfoAboutCon1 {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.BusinessInfoAboutText {
  width: 100%;
  min-height: 200px;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.BusinessInfoContactCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
/* End Bsiness Info */

/* NEW */
.BusinessEditorBCon1A {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--app-white, #fff);
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  z-index: 0;
}
.BusinessEditorBCon1 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessEditorBCon2 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--app-charcoal-dark);
}
.BusinessEditorBCon3 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.BusinessEditorBText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BusinessEditorBCon3B {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.BusinessEditorBCon4 {
  display: flex;
  padding: 0px 6px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.BusinessEditorBCon5 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px 6px 0px 0px;
  background: var(--app-white, #fff);
}
.BusinessEditorBText3 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.BusinessEditorBCon6 {
  display: flex;
  padding: 0px 12px 4px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.BusinessEditorBCon7 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset;
}
.BusinessEditorBText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.BusinessEditorBCon8 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.BusinessEditorBCon9B {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.BusinessEditorBCon9 {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 12px 12px 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  overflow-y: scroll;
  transition: max-height 0.3s ease-out; /* Smooth transition */
}

.BusinessEditorBCon9Shrinked {
  max-height: 2px; /* Start with 0px height for shrinked state */
  padding: 0px; /* Optional: Remove padding when shrunk */
  overflow: hidden; /* Ensure content is clipped when shrunk */
}

.BusinessEditorBCon9Expanded {
  max-height: 500px; /* Set a sufficiently large value for expansion */
}

.BusinessEditorBCon10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.BusinessEditorBCon11 {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  background: var(--app-white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.BusinessEditorBText5 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.BusinessEditorBCon12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.BusinessEditorBCon13 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.BusinessEditorInputBusinesTextEditableView {
  border-radius: 6px;
  border: solid 1px var(--app-color-editable-view);
}

.Wallet2Text3Dark {
  color: var(--app-charcoal-dark);
}
.editorErrorInputMessage {
  background: var(--app-white);
  border-radius: 2px;
  padding: 0px 2px;
}
