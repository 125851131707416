.MainContainerBusinessPageBuild {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.BusinessPageVisualizerCon1 {
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessPageVisualizerCon2 {
  background: var(--app-orange, #ff7043);
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  padding: 2px 10px;
  display: flex;
}

.BusinessPageVisualizerNameText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.BusinessPageVisualizerSectionText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessPageVisualizerCon3 {
  background: var(--app-white, #fff);
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: scroll;
}

.BusinessPageEditorCon1 {
  box-sizing: border-box;
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  height: 65%;
  transition: height .3s, padding .3s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 12px 2px #00000040;
}

.BusinessPageEditorCon1Hidden {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.BusinessPageEditorCon2 {
  border-top: 1px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  z-index: 200;
  border-radius: 12px 12px 0 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040, inset 0 0 8px 1px #00000040;
}

.BusinessPageEditorCon2Name {
  color: #fff;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.BusinessPageEditorConSilderUopDownCon1 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 24px;
  display: flex;
}

.BusinessPageEditorConSilderUopDownCon2 {
  background: #fff;
  border-radius: 12px;
  align-items: flex-start;
  gap: 10px;
  width: 48px;
  height: 6px;
  display: flex;
}

.BusinessPageEditorConSectionName {
  color: #fff;
  text-align: right;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessPageEditorCon3 {
  box-sizing: border-box;
  background: none;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 12px 12px 32px;
  display: flex;
  overflow: hidden scroll;
}

.BusinessPageEditorCon4 {
  box-sizing: border-box;
  background: var(--app-white, #fff);
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: inset 0 0 12px 2px #00000040, 0 4px 4px #00000040;
}

.BusinessPageEditorCon5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 16px 24px;
  display: flex;
}

.BusinessPageEditorCon6 {
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.BusinessPageEditorButtonCreatePage {
  background: var(--app-gold-yellow, #f4c542);
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .4px;
  border-radius: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 4px 24px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.BusinessPageEditorButtonBack {
  background: var(--app-orange, #ff7043);
  color: var(--app-white, #fff);
  border-radius: 12px 0 0 12px;
  flex: 1 0 0;
  align-items: center;
  gap: 6px;
  padding: 6px 12px 6px 6px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.BusinessPageEditorButtonCancel {
  background: var(--app-charcoal-dark, #2b2b2b);
  color: var(--app-white, #fff);
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.BusinessPageEditorButtonNext {
  background: var(--app-orange, #ff7043);
  color: var(--app-white, #fff);
  border-radius: 0 12px 12px 0;
  flex: 1 0 0;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  padding: 6px 6px 6px 12px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

.BusinessPageEditorButtonInactive {
  color: var(--app-white, #fff);
  background: #ff6f4384;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessEditorBusinessHeaderCon1 {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.BusinessEditorBusinessHeaderCon2 {
  background: linear-gradient(#000000b3 0%, #0000 100%), linear-gradient(179deg, #0000 -11.63%, #000c 99.49%);
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.BusinessEditorBusinessHeaderCon3 {
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  padding: 12px 24px;
  display: flex;
}

.BusinessEditorBusinessHeaderCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  display: flex;
}

.BusinessEditorBusinessHeaderCon5 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessEditorBusinessHeaderCon10 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  gap: 2px;
  padding: 6px 12px;
  display: flex;
}

.BusinessEditorBusinessHeaderImportantIconExit {
  height: 32px;
}

.BusinessEditorBusinessHeaderImportantIconVerified {
  height: 20px;
}

.BusinessEditorBusinessHeaderVerifiedBadgeCon1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  display: flex;
}

.BusinessEditorBusinessHeaderVerifiedBadgeText {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessEditorBusinessHeaderBusinessName {
  color: var(--app-white, #fff);
  text-align: center;
  letter-spacing: .24px;
  align-self: stretch;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessEditorBusinessHeaderBusinessShortDescription {
  color: var(--app-white, #fff);
  text-align: center;
  text-shadow: 1px 1.5px 4px #000000e6;
  letter-spacing: .24px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessEditorBusinessHeaderCon6 {
  background: var(--app-gray-medium);
  border: 2px solid #888;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  display: flex;
  overflow: hidden;
}

.BusinessEditorBusinessHeaderCon6Img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.BusinessEditorBusinessHeaderCon6ImgBackground {
  object-fit: cover;
  mix-blend-mode: multiply;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.BusinessEditorBusinessHeaderCon7 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessEditorBusinessHeaderCon8B {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.BusinessEditorBusinessHeaderCon8 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
  display: flex;
}

.BusinessEditorBusinessHeaderButtonCon1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.BusinessEditorBusinessHeaderButtonCon2 {
  background: #000000b3;
  border-radius: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  display: flex;
  box-shadow: 0 0 4px #ffffff80;
}

.BusinessEditorBusinessHeaderButtonLabel {
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessEditorBusinessHeaderCon9 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.ListingsExampleDisplayCon1 {
  background: var(--app-beige);
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  padding: 12px 0;
  display: flex;
}

.ListingsExampleDisplayCon2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.ListingsExampleDisplayHeaderTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .2px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ListingsExampleDisplayHeaderSeeAll {
  color: var(--app-green, #3a7d44);
  letter-spacing: .12px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.ListingsExampleDisplayCon3 {
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 18px;
  display: flex;
  overflow-x: scroll;
}

.ListingExampleVeriticalCon1 {
  border-radius: 5px;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 143px;
  display: flex;
}

.ListingExampleVeriticalCon2 {
  background: #585858cc;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 54px;
  padding: 25px 58px;
  display: flex;
}

.ListingExampleVeriticalCon3 {
  background: #c3c3c3;
  border: .5px solid #999;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingExampleVeriticalCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.ListingExampleVeriticalCon5 {
  background: #999;
  border-radius: 0 0 2px 2px;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 2px 6px;
  display: flex;
}

.ListingExampleVeriticalTitle {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingExampleVeriticalCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.ListingExampleVeriticalCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.ListingExampleVeriticalCon8 {
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.ListingExampleVeriticalCon9 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.ListingExampleVeriticalCon10 {
  background: var(--Item-backg-highlight, #ff3c00);
  border-radius: 0 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 2px;
  display: flex;
}

.ListingExampleVeriticalOnDiscount {
  color: var(--Item-text-highlight, orange);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingExampleVeriticalPrice {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ListingExampleVeriticalCondition {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.ListingExampleVeriticalCon11 {
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  display: flex;
}

.ListingVeriticalPriceBeforeText, .ListingVeriticalPriceBeforePrice {
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ListingExampleVeriticalDescription {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .08px;
  align-self: stretch;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ListingExampleVeriticalCon12 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  height: 30px;
  padding: 6px 12px;
  display: flex;
}

.ListingExampleVeriticalCon13 {
  align-items: center;
  gap: 12px;
  display: flex;
}

.ListingExampleVeriticalCon14, .ListingExampleVeriticalCon15 {
  background: var(--app-charcoal-dark, #2b2b2b);
  border-radius: 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 4px;
  display: flex;
  box-shadow: 0 2px 4px 1px #fff;
}

.ListingExampleVeriticalCon16 {
  align-items: center;
  gap: 2px;
  display: flex;
}

.ListingExampleVeriticalViewsText {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessInfoPortionLinkCon1 {
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.BusinessInfoPortionLinkCon1B {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 100%;
  display: flex;
}

.BusinessInfoPortionLinkText {
  width: 100%;
  color: var(--app-link-color, #00349b);
  letter-spacing: .2px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessInfoCon1 {
  border-radius: 12px;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessInfoCon2 {
  background: var(--app-gray-medium, #6d6d6d);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  display: flex;
}

.BusinessInfoHeaderText {
  color: var(--app-white, #fff);
  letter-spacing: .2px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessInfoCon3 {
  background: var(--app-gray-soft, #e0e0e0);
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px;
  display: flex;
}

.BusinessInfoPortionCon1 {
  background: var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 12px 6px;
  display: flex;
}

.BusinessInfoPortionCon2 {
  border-bottom: 1px solid var(--app-gray-soft, #e0e0e0);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 0;
  display: flex;
}

.BusinessInfoPortionHeaderText {
  color: var(--app-gray-medium, #6d6d6d);
  text-align: center;
  letter-spacing: .16px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessInfoLocationCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessInfoLocationCon2 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  padding: 0 6px;
  display: flex;
}

.BusinessInfoLocationHeaderText {
  color: var(--app-gray-medium, #6d6d6d);
  letter-spacing: .12px;
  align-self: stretch;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessInfoLocationCon3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.BusinessInfoLocationDescriptionText {
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .14px;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessInfoAboutCon1 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.BusinessInfoAboutText {
  width: 100%;
  min-height: 200px;
  color: var(--app-charcoal-dark, #2b2b2b);
  letter-spacing: .36px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BusinessInfoContactCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessEditorBCon1A {
  border-bottom: 1px solid var(--app-white, #fff);
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  z-index: 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 6px 0;
  display: flex;
  box-shadow: 0 4px 12px #00000040;
}

.BusinessEditorBCon1 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 12px;
  display: flex;
}

.BusinessEditorBCon2 {
  border: 1px solid var(--app-charcoal-dark);
  border-radius: 6px;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 3px 6px;
  display: flex;
}

.BusinessEditorBCon3 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessEditorBText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessEditorBCon3B {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.BusinessEditorBCon4 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  padding: 0 6px;
  display: flex;
}

.BusinessEditorBCon5 {
  background: var(--app-white, #fff);
  border-radius: 6px 6px 0 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: flex;
}

.BusinessEditorBText3 {
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessEditorBCon6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 0 12px 4px;
  display: flex;
}

.BusinessEditorBCon7 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 3px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: flex;
  box-shadow: -2px 0 4px #00000040, inset 0 0 4px #00000080;
}

.BusinessEditorBText4 {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BusinessEditorBCon8 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 0 6px;
  display: flex;
}

.BusinessEditorBCon9B {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  padding: 12px;
  display: flex;
}

.BusinessEditorBCon9 {
  background: var(--app-white, #fff);
  border-radius: 0 12px 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: 0;
  transition: max-height .3s ease-out;
  display: flex;
  overflow: hidden scroll;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessEditorBCon9Shrinked {
  max-height: 2px;
  padding: 0;
  overflow: hidden;
}

.BusinessEditorBCon9Expanded {
  max-height: 500px;
}

.BusinessEditorBCon10 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.BusinessEditorBCon11 {
  background: var(--app-white, #fff);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 4px 6px;
  display: flex;
  box-shadow: 0 2px 4px #00000040;
}

.BusinessEditorBText5 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BusinessEditorBCon12 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.BusinessEditorBCon13 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
  box-shadow: 0 4px 4px #00000040;
}

.BusinessEditorInputBusinesTextEditableView {
  border: solid 1px var(--app-color-editable-view);
  border-radius: 6px;
}

.Wallet2Text3Dark {
  color: var(--app-charcoal-dark);
}

.editorErrorInputMessage {
  background: var(--app-white);
  border-radius: 2px;
  padding: 0 2px;
}
/*# sourceMappingURL=index.f5032fff.css.map */
